import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Grow from '@material-ui/core/Grow';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    maxWidth: '400px', // Adjust based on your needs
  },
}));

export default function SimplePopover({max, uuid, addToCart, cart, icon = 'shopping-cart'}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const quantityClicked = ({ uuid, quantityToAdd }) => {
    handleClose();
    addToCart({ uuid, quantityToAdd });
  };

const removeFromCartButton = (() => {
  const isDisabled = cart === undefined || cart.pending || cart.quantity === 0;
  const hasError = !!cart?.error;

  return (
    <Grid container spacing={1}>
      <Grid item xs={4}>
        <Button
          color="secondary"
          variant="contained"
          disabled={isDisabled}
          onClick={!isDisabled ? () => quantityClicked({ uuid, quantityToAdd: 0 }) : undefined}
        >
          <DeleteIcon />
        </Button>
      </Grid>
      <Grid item xs={8}>
        <Typography
          variant="h6"
          gutterBottom
          style={{
            paddingRight: '8px',
            fontSize: hasError ? '0.75rem' : '1rem'
          }}
        >
          {cart?.error || 'Select Quantity'}
        </Typography>
      </Grid>
    </Grid>
  );
})();

  const buttons = Array(max).fill().map((_, idx) => (
    <Grid item xs={2} key={uuid + idx}>
      <Button
        style={{ width: '100%' }}
        variant="contained"
        onClick={() => quantityClicked({ uuid, quantityToAdd: idx + 1 })}
      >
        {idx + 1}
      </Button>
    </Grid>
  ));

  let button;
  if (cart === undefined || cart.quantity === 0) {
    button = <button className="btn btn-dark" type="submit" value="Add to Cart"
      aria-describedby={id} variant="contained" color="primary" onClick={handleClick}>
      <i className={`fa fa-${icon}`}></i>
    </button>
  } else if (cart.pending) {
     button = <button style={{width: '44px'}} className="btn btn-warning" readOnly>
       <i className="fa fa-hourglass-half"></i>
     </button>
   } else if (cart.error) {
     button = <Tooltip title={`${cart.error}`} aria-label="error">
       <button style={{width: '44px'}} className="btn btn-danger" onClick={handleClick}>
         <i className="fa fa-exclamation-triangle"></i>
       </button>
     </Tooltip>
   } else {
     button = <button style={{width: '44px'}} className="btn btn-success" type="submit" value="Add to Cart"
       aria-describedby={id} variant="contained" color="primary" onClick={handleClick}>
       {cart.quantity}
     </button>
  }

  return (
    <div>
      {button}

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        TransitionComponent={Grow}
        disableAutoFocus
        disableEnforceFocus
        disableRestoreFocus
      >
        <div className={classes.root}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              {removeFromCartButton}
            </Grid>
            {buttons.map((button, index) => (
              <Grid item xs={3} key={index}>
                {button}
              </Grid>
            ))}
          </Grid>
        </div>
      </Popover>
    </div>
  );
}

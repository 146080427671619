import React, { useState } from "react";

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';

import MassPricingToolbar from './MassPricingToolbar.js';

import axios from 'axios';

const MassPricing = (props) => {
  const [results, setResults] = useState([{id: "placeholder", name: "~", set_code: "~"}]);
  const [searchTerm, setSearchTerm] = useState("");

  const currency = (amount) => {
    const numericAmount = parseFloat(amount);

    if (amount == undefined) {
      return "";
    }

    if (isNaN(numericAmount)) {
      return "Invalid Amount";
    }

    const formattedAmount = (numericAmount / 100).toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });

    return formattedAmount;
  };

  const getSearchResults = async (searchTerm) => {
    if (searchTerm == "" || searchTerm == undefined) {
      return
    }
    try {
      const response = await axios.get(`${props.searchUrl}`, {
        params: {
          search_term: searchTerm
        }
      });

      setResults(response.data.results);
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

  const afterProcessing = () => {
    getSearchResults(searchTerm)
  }

  const autocompleteOnChange = (selectedValue) => {
    setSearchTerm(selectedValue)
    getSearchResults(selectedValue)
  }

  return (
    <>
    <Container>
      <Grid container item spacing={1}>
        <Grid item xs={12}>
          <MassPricingToolbar autocompleteUrl={props.autocompleteUrl} onChange={autocompleteOnChange} searchTerm={searchTerm} afterProcessing={afterProcessing} />
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>Finish</StyledTableCell>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>Set Code</StyledTableCell>
              <StyledTableCell>Buy Price</StyledTableCell>
              <StyledTableCell>Sell Price</StyledTableCell>
              <StyledTableCell>Min Sell Price</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {results.map((result) => (
              <StyledTableRow key={result.id}>
                <StyledTableCell>{result.finish}</StyledTableCell>
                <StyledTableCell>{result.name}</StyledTableCell>
                <StyledTableCell><i className={`ss ss-${result.set_code} ss-${result.rarity} ss-2x mr-2`}></i>{result.set_code.toUpperCase()}</StyledTableCell>
                <StyledTableCell>{currency(result.buy_price)}</StyledTableCell>
                <StyledTableCell>{currency(result.sell_price)}</StyledTableCell>
                <StyledTableCell>{currency(result.minimum_sell_price_in_cents)}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
    </>
  );
};

export default MassPricing;

import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from '@material-ui/core/styles';

import MuiAutocomplete from './MuiAutocomplete.js';

import axios from "axios";

const MassPricingToolbar = ( {autocompleteUrl, onChange, searchTerm, afterProcessing}) => {

  const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '25ch',
        display: 'inline-flex'
      },
    },
  }));
  const classes = useStyles();
  const [minPrice, setMinPrice] = useState("");
  const [operator, setOperator] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const paramsNotFilled = () => {
    return !searchTerm || !minPrice || !operator;
  };

  const handleSubmit = () => {
    setLoading(true);

    axios
      .post("/admin/mass_pricing/set_pricing", {
        searchTerm: searchTerm,
        price: minPrice,
        selectedOperator: operator,
        authenticity_token: Rails.csrfToken()
      })
      .then((response) => {
        setMessage(response.data.message);
      })
      .catch((error) => {
        setMessage("An error occurred while processing your request.");
      })
      .finally(() => {
        setLoading(false);
        afterProcessing();
      });
  };

  const handleChange = (event) => {
    setOperator(event.target.value);
  }

  return (
    <div>
    <form className={classes.root} noValidate autoComplete="off">
      <MuiAutocomplete autocompleteUrl={autocompleteUrl} onChange={onChange} />
      <TextField
        id="outlined-select-currency-native"
        select
        label="Operation"
        value={operator}
        onChange={handleChange}
        variant="outlined"
      >
        {
          [
            {
              name: "INCREASE ALL: Set minimum cards on ALL cards currently under to",
              key: "increase",
            },
            {
              name: "REMOVE: Remove minimum price from cards current set to:",
              key: "remove",
            },
            {
              name: "SET UNASSIGNED: Set cards without minimum values",
              key: "set_nils",
            }
          ]
          .map((option) => (
          <MenuItem key={option.key} value={option.key}>
            {option.name}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        id="outlined-input-minimum-price"
        label="Price"
        value={minPrice}
        onChange={(e) => setMinPrice(e.target.value)}
        variant="outlined"
      />
      <Button
        disabled={paramsNotFilled()}
        size="large"
        variant="contained"
        color="primary"
        onClick={handleSubmit}
      >
        Submit
      </Button>
    </form>
    <Dialog open={loading}>
    <DialogContent>
      <CircularProgress />
    </DialogContent>
    </Dialog>
    {message && <p className="submit-response">{message}</p>}
    </div>
  );
};
export default MassPricingToolbar;

import React from 'react';
import { Grid, Paper, Typography, Button } from '@material-ui/core';

const ErrorSubmissionReport = ({ submissionResult }) => (
  <Grid container spacing={3}>
    <Grid item xs={12}>
      <Paper className="alert alert-danger alert-dismissible fade show" role="alert">
        <pre>{JSON.stringify(submissionResult, null, 2)}</pre>
        <Button
          type="button"
          className="close"
          data-dismiss="alert"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </Button>
      </Paper>
    </Grid>
  </Grid>
);

export default ErrorSubmissionReport;

import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Icon from '@material-ui/core/Icon';
import { createTheme, withStyles, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { green, brown } from '@material-ui/core/colors';
import InflightButton from './GlimmerpoolInflightButton.js'
import ReleaseButton from './GlimmerpoolReleaseButton.js'

const inflightPath = 'move_storage_locations_in_flight';
const releasePath = 'release_storage_locations';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    minWidth: 500,
    maxWidth: 500,
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  paddingTop: {
    marginTop: 12,
  }
}));

const ColorButton = withStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    color: '#ffffff',
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
}))(Button);

const BrownButton = withStyles((theme) => ({
  root: {
    marginRight: theme.spacing(1),
    color: '#ffffff',
    backgroundColor: brown[500],
    '&:hover': {
      backgroundColor: brown[700],
    },
  },
}))(Button);

function calculateTotalByKey(obj, key) {
  return Object.values(obj).reduce((total, storageLocation) => {
    if (storageLocation.quantities[key] !== undefined) {
      // Convert strings to numbers if the value is a string representing a number
      const value = typeof storageLocation.quantities[key] === 'string' ? parseFloat(storageLocation.quantities[key]) : storageLocation.quantities[key];

      // Check if the value is a valid number
      if (!isNaN(value)) {
        return total + value;
      }
    }
    return total;
  }, 0);
}

export default function GlimmerpoolShelf({ shelfNumber, storageLocations }) {
  const [checkedItems, setCheckedItems] = React.useState([]);
  const classes = useStyles();

  const handleCheckboxChange = (setCode) => {
    setCheckedItems((prevCheckedItems) => {
      if (prevCheckedItems.includes(setCode)) {
        return prevCheckedItems.filter((item) => item !== setCode);
      } else {
        return [...prevCheckedItems, setCode];
      }
    });
  };

const SelectAllButton = ({}) => {
  const buttonText = checkedItems.length > 0 ? 'Deselect All' : 'Select All';

  const handleButtonClick = () => {
    if (checkedItems.length > 0) {
      setCheckedItems([]);
    } else {
      setCheckedItems(Object.values(storageLocations))
    }
  };

  return (
    <Button size="small" variant="outlined" color="primary" onClick={handleButtonClick}>
      {buttonText}
    </Button>
  );
};

  const CardInformation = () => {
    return (
      <Typography className={`${classes.pos} ${classes.paddingTop}`} color="textSecondary">
        Selected Storage Locations: {checkedItems.map((storage_location) => (
          <>
            {storage_location.set_code.toUpperCase() + ", "}
          </>
          ))}
      </Typography>
    );
  };

  const ShelfInformation = () => {
    return (
      <>
        <div style={{ justifyContent: 'space-between', display: 'flex', gap: '0.5rem', width: '100%' }}>
          <Typography variant="h5" gutterBottom>
            Shelf <span className="shelf-number">{shelfNumber}</span>
          </Typography>
          <SelectAllButton checkedItems={checkedItems} />
        </div>
        <Typography variant="body2" color="textSecondary">
          <strong>Total</strong> In Flight: {calculateTotalByKey(storageLocations, 'in_flight_from_glimmerpool') } | Waiting: {calculateTotalByKey(storageLocations, 'in_glimmerpool')}
        </Typography>
      </>
    );
  };

  const SetInformation = ({ storageLocation }) => {
    const isChecked = checkedItems.includes(storageLocation);

    return (
      <>
      <ListItem className="storage-location" alignItems="flex-start">
        <Checkbox
          className='storage-location-checkbox'
          value={storageLocation.set_code}
          checked={isChecked}
          onClick={() => handleCheckboxChange(storageLocation)}
          inputProps={{ 'aria-label': 'Checkbox' }}
        />
        <ListItemText 
          primary={
            <React.Fragment>
              <Typography variant="h6">
              <i className={`set-code ss ss-${storageLocation.set_code} ss-common mr-2` } value={storageLocation.set_code}></i>
                <span className="storage-location-name">
                  {storageLocation.name}
                </span>
              </Typography>
            </React.Fragment>
          }
          secondary={
            <React.Fragment>
              <Typography variant="body2" color="textSecondary">
                Row: {storageLocation.shelf_row} - Box: {storageLocation.shelf_box_number} - Position: {storageLocation.shelf_position_in_box}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                In Flight: {storageLocation.quantities.in_flight_from_glimmerpool || 0 } | Waiting: {storageLocation.quantities.in_glimmerpool || 0 }
              </Typography>
            </React.Fragment>
          }
        />
      </ListItem>
      <Divider light/>
      </>
    );
  };

  const ChecklistButton = () => {
    const handleButtonClick = () => {
      const storageLocationIds = checkedItems.map(item => item.id);
      const url = `checklist_glimmerpooled_storage_locations?${storageLocationIds.map(id => `ids[]=${id}`).join('&')}`;
      window.open(url, '_blank');
    };
    const size = checkedItems.length;
    const disabled = size < 1 ;

    return (
      <>
        <BrownButton
          variant="contained"
          color="primary"
          startIcon={<Icon className="fa fa-clipboard" />}
          onClick={handleButtonClick}
          disabled={disabled}
        >
          View Checklist for {size} Selected Sets
        </BrownButton>
      </>
    );
  };

  return (
    <Card className={classes.root}>
      <CardContent className="shelf">
        <ShelfInformation />
        <InflightButton checkedItems={checkedItems} calculateTotalByKey={calculateTotalByKey} submitPath={inflightPath} classes={classes} />
        <ReleaseButton checkedItems={checkedItems} calculateTotalByKey={calculateTotalByKey} submitPath={releasePath} classes={classes} />
        <ChecklistButton />
        <CardInformation />
        <Divider light/>
        <List>
          {Object.keys(storageLocations).map((storageLocationId) => (
            <SetInformation key={storageLocationId} storageLocation={storageLocations[storageLocationId]} />
          ))}
        </List>
      </CardContent>
    </Card>
  );
}

import React from "react"
import CardImagePopover from './CardImagePopover.js'

export default function Row({
  visibleToPublic,
  cardSkuId,
  symbol, name,
  linkPath, setLinkPath, writeOffPath, buylistCartPath,
  image, finish, condition, setName, setCode,
  write_off_last_copy,
  addToBuylist, sellPrice, flags, cachedQuantityInStock,
  quantityWanted, lastNinetyDays, last365Days, lastSold, buyPrice, creditPrice,
  filters
}) {

  const VisibleToPublicIcon = () => {
    let icon
    if (visibleToPublic) {
      icon = <></>
    } else {
      icon = <i className="fa fa-times-circle text-danger"></i>
    }
    return icon
  }

  const SetSymbol = () => {
    let rarity = "common"
    return <i className={symbol} />
  }

  const DollarValue = ({cents}) => {
    return <span>{ (cents / 100 ).toLocaleString("en-US", {style:"currency", currency:"USD"}) }</span>
  }

  const Flag = ({flag}) => {
    switch (flag) {
      case 'priced_up':
        return <span className="badge badge-label badge-dark">Priced Up</span>
      case 'hotlist':
        return <span style={{backgroundImage: "linear-gradient(to bottom right,red,yellow)"}} className="badge badge-label">
          Hotlist
        </span>
      case 'buylist_override':
        return <span className="badge badge-label badge-info">
          Buylist Override
        </span>
      default:
        return <span className="badge badge-label badge-secondary">{flag}</span>
    }
  }

  const formatFlags = (flags) => {
    return flags.map(
      (flag, index, json) => {
        return <Flag flag={flag} key={`${index}${cardSkuId}` } />
      }
    )
  }

  const FoilPill = ({finish}) => {
    let badge = null
    if(finish == "Foil") {
      badge =
        <>
        <span style={{
          backgroundImage: "linear-gradient(135deg, rgba(222,197,37,0) 0%, rgba(222,197,37,0) 1%, rgba(255,8,8,0.06) 19%, rgba(239,111,23,0.1) 32%, rgba(222,213,37,0.23) 45%, rgba(36,222,101,0.39) 62%, rgba(47,221,109,0.4) 63%, rgba(216,208,239,0.39) 79%, rgba(131,123,173,0.38) 88%, rgba(136,129,178,0.38) 89%, rgba(193,191,234,0) 100%)",
        }} className="badge badge-label">
        Foil
        </span>
        </>
    } else if(finish == "Etched") {
      badge =
        <>
        <span style={{
          background: "linear-gradient(135deg, #FCF6BA, #FBF5B7, #d49d3b)"
        }} className="badge badge-label">
        Etched
        </span>
        </>
    } else {
      badge = <small className="text-muted">~</small>
    }
    return badge
  }

  const SetName = () => {
    return <a href={setLinkPath}>{setCode} - {setName}</a>
  }

  const WriteOffLastCopy = () => {
    let button
    if (cachedQuantityInStock > 0) {
      button = <a href={writeOffPath} className="btn btn-danger btn-sm" data-method="post" rel="nofollow" >
      <i className="fa fa-trash"></i>
      </a>
    } else {
      button = <></>
    }
    return button
  }

  const AddToBuylist = () => {
    return <form className="form-inline" action={buylistCartPath} acceptCharset="UTF-8" method="post">
      <input name="utf8" type="hidden" value="✓" />
      <input type="hidden" name="authenticity_token" value={ Rails.csrfToken() } />
      <div className="input-group input-group-sm">
        <input type="hidden" name="card_sku_id" id="card_sku_id" value={cardSkuId} />
        <input type="text" name="quantity" id="quantity" defaultValue={ 1 } size="1" />
        <div className="input-group-append">
          <button name="button" type="submit" className="btn btn-success">
          <i className="fa fa-check"></i>
          </button>
        </div>
      </div>
    </form>
  }

  const visibleToPublicFilter = () => {
    let result
    if (filters.visibilityToggle == visibleToPublic.toString()){
      result = true
    } else {
      result = false
    }
    if (filters.visibilityToggle == "all") {
      result = true
    }
    return result
  }

  const finishFilter = () => {
    let result
    if (filters.finishToggle == finish.toLowerCase()){
      result = true
    } else {
      result = false
    }
    if (filters.finishToggle == "all") {
      result = true
    }
    return result
  }

  const displayRow = () => {
    if(visibleToPublicFilter() && finishFilter()) {
      return "table-row"
    } else {
      return "none"
    }
  }

  const Quantity = ({quantity}) => {
    let integer = _.toInteger(quantity)
    let color
    switch (true) {
      case (integer == 0):
        color = "table-danger"
        break;
      case (integer < 4):
        color = "table-warning"
    }
    return <td className={`text-monospace text-right ${color}`}>{ quantity }</td>
  }

  return(
    <tr style={ {display: displayRow()} }>
      <td className="text-center"><VisibleToPublicIcon /></td>
      <td><SetSymbol /></td>
      <td><CardImagePopover name={name} link={linkPath} image={image} finish={finish} /></td>
      <td><SetName /></td>
      <td className="text-center"><FoilPill {...{finish}} /></td>
      <td className="text-center"><WriteOffLastCopy /></td>
      <td className="text-center"><AddToBuylist /></td>
      <td className="text-monospace text-right"><DollarValue cents={sellPrice} /></td>
      <td className="text-center">{ formatFlags(flags) }</td>
      <Quantity quantity={cachedQuantityInStock} />
      <td className="text-monospace text-right">{ quantityWanted }</td>
      <td className="text-monospace text-right">{ lastNinetyDays }</td>
      <td className="text-monospace text-right">{ last365Days }</td>
      <td className="text-right">{ lastSold }</td>
      <td className="text-monospace text-right"><DollarValue cents={buyPrice} /></td>
      <td className="text-monospace text-right"><DollarValue cents={creditPrice} /></td>
    </tr>
  )
}

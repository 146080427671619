import React, { useState } from 'react';
import { Grid, TextField, Input, InputLabel, Button } from '@material-ui/core';

const FileAttachFormElement = ({ csvFile, setCsvFile }) => {
  const handleFileChange = (event) => {
    setCsvFile(event.target.files[0]);
  };

  return (
    <Grid container alignItems="center">
      <Grid item>
        <Input
          fullWidth
          id="csv"
          name="csv"
          type="file"
          inputProps={{ accept: ".csv" }}
          onChange={handleFileChange}
          style={{ display: 'none' }} // Hide the default input appearance
        />
      </Grid>
      <Grid item>
        <label htmlFor="csv">
          <Button variant="contained" component="span" color="primary">
            Choose File
          </Button>
        </label>
      </Grid>
      <Grid item>
        {csvFile && (
          <p style={{ marginLeft: '10px' }}>Selected file: {csvFile.name}</p>
        )}
      </Grid>
    </Grid>
  );
};

export default FileAttachFormElement;

import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import MagicSetTable from './FilterableTable.js';

class FilterableTableLoaded extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cards: null,
      uuid: null,
      title: null,
      cart_data: props.cart_data,
      currentUserId: props.userId,
      loading: true
    };
  }

  componentDidMount() {
    const { dataUrl } = this.props;
    
    fetch(dataUrl)
      .then(response => response.json())
      .then(data => {
        this.setState({
          cards: data.card_data,
          uuid: data.uuid_data,
          title: data.title,
          loading: false
        });
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        this.setState({ loading: false });
      });
  }

  render() {
    const { cards, uuid, title, cart_data, currentUserId, loading } = this.state;

    if (loading) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress />
        </Box>
      );
    }

    return (
      <MagicSetTable
        currentUserId={currentUserId}
        {...{ cards, uuid, title, cart_data, ...this.props }}
      />
    );
  }
}

export default FilterableTableLoaded;

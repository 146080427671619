import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
}));

export default function SimplePopover({max}) {
  const classes = useStyles();

  return (
    <div>
      <button className="btn btn-danger" type="submit" value="Add to Cart"
        variant="contained" color="primary" disabled={true} >
        <i className="fa fa-ban"></i>
      </button>
    </div>
  );
}

import React from 'react';
import { Grid, Paper, Typography, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';

const SubmissionMessage = ({ count, email, filename }) => (
  <Grid item xs={8}>
    <Paper style={{ backgroundColor: '#cce5ff', padding: '10px' }}>
      <Typography variant="body1" style={{ color: '#004085' }}>
        Created {count} buylists for user: <strong>{email}</strong> using <strong>{filename}</strong>
      </Typography>
    </Paper>
  </Grid>
);

const ProblemRowsTable = ({ problemRows }) => {
  const headers = problemRows.length > 0 ? problemRows[0] : [];

  return (
    <Grid item xs={12}>
      <Typography variant="h6" align="center" style={{ color: 'red', fontWeight: 'bold' }}>
        Import Issues
      </Typography>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              {headers.map((header, index) => (
                <TableCell key={index}>{header}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {problemRows.slice(1).map((row, index) => ( // Skip the first row as it contains headers
              <TableRow key={index}>
                {row.map((cell, idx) => (
                  <TableCell key={idx}>{cell}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

// Download CSV button component
const DownloadCsvButton = ({ overstockCsv }) => {
  const handleDownloadCsv = () => {
    const timestamp = new Date().toISOString().replace(/[-:]/g, '').slice(0, -5); // Format: YYYYMMDDTHHMMSS
    const userEmail = overstockCsv.email.replace(/[@]/g, ''); // Remove '@' from email
    const filename = `${timestamp}-${userEmail}-overstock.csv`;

    const blob = new Blob([overstockCsv.overstock_csv], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      {overstockCsv && (
        <Grid item xs={4}>
          <Button variant="contained" color="primary" onClick={handleDownloadCsv}>
            Download Overstock CSV
          </Button>
        </Grid>
      )}
    </>
  );
};

const InfoSubmissionReport = ({ submissionResult }) => (
  <Grid container spacing={3}>
    <Grid item xs={12}>
      <Paper className="alert alert-info alert-dismissible fade show" role="alert">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h6" align="center" style={{ color: 'black', fontWeight: 'bold' }}>
              Submission Report
            </Typography>
          </Grid>
          <SubmissionMessage count={submissionResult.count} email={submissionResult.email} filename={submissionResult.original_filename} />
          <DownloadCsvButton overstockCsv={submissionResult} />
          <ProblemRowsTable problemRows={submissionResult.problem_rows} />
        </Grid>
      </Paper>
    </Grid>
  </Grid>
);

export default InfoSubmissionReport;

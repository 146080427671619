import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import GlimmerpoolShelf from './GlimmerpoolShelf.js'

const useStyles = makeStyles((theme) => ({
  grid: {
    padding: 20,
    backgroundColor: '#F5F5F5',
  },
}));

export default function SpacingGrid({shelves}) {
  const [spacing, setSpacing] = React.useState(2);
  const classes = useStyles();

  return (
    <Grid container className={classes.grid} spacing={2}>
      <Grid item xs={12}>
        <Grid container justifycontent="center" spacing={spacing}>
          {Object.keys(shelves).map((shelfNumber) => (
            <Grid key={shelfNumber} item>
              <GlimmerpoolShelf shelfNumber={shelfNumber} storageLocations={shelves[shelfNumber]} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}

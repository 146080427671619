import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import axios from 'axios'

export default function AlertDialog({cardPrinting, maxQuantity, initialMissingQuantity, missingCardsUrl}) {
  const colorClass = (value) => {
    let result
    if (value > 0) {
      result = "secondary"
    } else {
      result = "primary"
    }
    return result
  }

  const [state, setState] = React.useState(
    {
      cardPrintingObject: JSON.parse(cardPrinting),
      missingQuantity: initialMissingQuantity,
      open: false,
      pickerQuantity: initialMissingQuantity,
      color: colorClass(initialMissingQuantity),
    }
  )
  const {cardPrintingObject, missingQuantity, open, pickerQuantity, color} = state

  const handleClickOpen = () => {
    setState({...state, open: true})
  };

  const handleClose = () => {
    setState({...state, open: false})
  };

  const submitForm = () => {
    axios.post(missingCardsUrl, {
      quantity: pickerQuantity,
      card_sku_id: cardPrintingObject.id,
      authenticity_token: Rails.csrfToken()
      }
    ).then(response => console.log(response))

    setState({
      ...state,
      missingQuantity: pickerQuantity,
      open: false,
      color: colorClass(pickerQuantity),
    })
  };

  const handleQuantityChange = (event) => {
    setState({...state, pickerQuantity: event.target.value})
  };

  const quantityDisplay = (() => {
    let result
    if (missingQuantity > 0) {
      result = `${maxQuantity - missingQuantity} / ${maxQuantity}`
    } else {
      result = maxQuantity
    }
    return result
  })()

  const quantityOptions = Array(maxQuantity+1).fill().reverse().map((data, idx) => {
    let quantityValue = maxQuantity - idx
    return <option key={quantityValue} value={quantityValue}>{quantityValue}</option>
  })

  return (
    <div>
      <Button size="small" variant="outlined" color={color} aria-label="missing cards" component="span" onClick={handleClickOpen}>
        {quantityDisplay}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          How many <strong>{cardPrintingObject.name}</strong> are missing?
        </DialogTitle>
        <DialogContent>
          <FormControl variant="outlined">
            <InputLabel htmlFor="quantity">Missing</InputLabel>
            <Select
              native
              style={{minWidth: 300}}
              value={pickerQuantity}
              onChange={handleQuantityChange}
              label="Quantity"
              inputProps={{
                name: 'quantity',
                id: 'quantity',
              }}
            >
              {quantityOptions}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button variant="contained" type="submit" onClick={submitForm} color="secondary">
            Mark {pickerQuantity} copies as missing
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

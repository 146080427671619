const channels = {}

function createChannel(channelId) {
  if (!channels[channelId]) {
    channels[channelId] = new EventTarget()
  }

  return channels[channelId]
}

const MESSAGE_EVENT = "message"

function sendMessage(channel, messageText) {
  const evt = new Event(MESSAGE_EVENT)
  evt.message = messageText
  channel.dispatchEvent(evt)
}

function subscribeMessage(channel, listener) {
  channel.addEventListener(MESSAGE_EVENT, listener)
}

export {
  createChannel,
  sendMessage,
  subscribeMessage,
}

import React from "react"
import Autosuggest from 'react-autosuggest'
import _ from 'lodash'

function appendSearchTerm(json, searchTerm) {
  let result = json.find(({name}) => name.toLowerCase() === searchTerm.toLowerCase())
  if (result === undefined) {
    return [{name: searchTerm, userInput: true},...json]
  } else {
    return json
  }
  return values
}

function getSuggestionValue(suggestion) {
  return suggestion.name;
}

function renderSuggestion(suggestion) {
  return (
    <span>{suggestion.name}</span>
  );
}

class CardAutocomplete extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      onSelected: props.onSelected,
      value: '',
      suggestions: [],
      isLoading: false,
      searchTerm: ''
    };
    this.debouncedScryfallAutoComplete = _.debounce(this.getScryfallAutoComplete, 250)
  }

  componentDidMount() {
    fetch(this.props.autocompleteUrl)
      .then(response => response.json())
      .then(response => response.map(cardName => {return {name: cardName}}))
      .then(response => this.cardNames = response)
  }

  getScryfallAutoComplete = ({value}) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    let suggestions = inputLength < 4 ? [] : this.cardNames.filter(lang => {
      return lang.name.toLowerCase().indexOf(inputValue) > -1
    });

    this.setState({
      searchTerm: value,
      suggestions: appendSearchTerm(suggestions.slice(0,9), value)
    })
  }

  onChange = (event, { newValue }) => {
    let val = newValue
    if (newValue.includes("’")) { // Replace iOS Smart Quotes
      val = newValue.replace("’","'")
    }

    this.setState({
      value: val
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  onSuggestionSelected = (e, {suggestion}) => {
    if (suggestion.userInput) {
      this.setState({value: this.state.value})
      this.state.onSelected(this.state.value)
    } else {
      this.state.onSelected(suggestion.name)
      this.setState({value: suggestion.name})
    }
  }

  onKeyDown = (event) => {
    switch (event.keyCode) {
      case 13: {
        if (_.isEmpty(this.state.suggestions)) {
          this.onSuggestionSelected(event, {suggestion: {userInput: true}})
        }
      }
    }
  };

  render() {
    const { value, suggestions, isLoading } = this.state;
    const inputProps = {
      placeholder: this.props.placeholder || 'Search',
      value,
      onChange: this.onChange,
      onKeyDown: this.onKeyDown,
    };

    return (
      <>
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.debouncedScryfallAutoComplete}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          onSuggestionSelected={this.onSuggestionSelected}
          highlightFirstSuggestion={true}
          inputProps={inputProps}
        />
      </>
    );
  }
}

export default CardAutocomplete

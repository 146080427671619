import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import green from '@material-ui/core/colors/green';
import AlertDialog from './PreorderWarning.js';
import StoreInformation from './StoreInformation.js';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const ConfirmButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(green[600]),
    backgroundColor: green[600],
    '&:hover': {
      backgroundColor: green[800],
    },
  },
}))(Button);

export default function NativeSelects(props) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    shipping: '',
    containsPreorders: props.containsPreorders,
    multimergeable: props.multimergeable,
    displayMergeOptions: props.multimergeable,
    inStorePickup: false,
  });

  const handleShippingMethodChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setState({
      ...state,
      [name]: value,
      displayMergeOptions: value === "merge",
      inStorePickup: value === "pickup",
    });
  };

  const handleMergeOrderChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  const shippingOptions = props.shippingOptions.map((data, idx) => {
    return <option key={idx} value={data[0]}>{data[1]}</option>
  })

  const mergeOptions = props.mergeOptions.map((data, idx) => {
    return <option key={idx} value={data[0]}>{data[1]}</option>
  })

  const confirmation = (() => {
    let button = <ConfirmButton type="submit" variant="contained" color="primary" className={classes.margin}>
      Confirm Order
    </ConfirmButton>
    if (state.containsPreorders == true) {
      button = AlertDialog({preorderDate: props.preorderShippingDate})
    }
    return button
  })()

  const mergeOptionSelect = (() => {
    if (state.displayMergeOptions === true) {
      return (
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel htmlFor="orderToMergeWith">Order to Merge with</InputLabel>
          <Select
            native
            value={state.order_to_merge_with}
            onChange={handleMergeOrderChange}
            label="Order to Merge with"
            inputProps={{
              name: 'order_to_merge_with',
              id: 'orderToMergeWith',
            }}
          >
            {mergeOptions}
          </Select>
        </FormControl>
      );
    }
    return null;
  })();

  const storeInfo = (() => {
    if (state.inStorePickup) {
      return (
        <StoreInformation />
      );
    }
    return null;
  })();

  return (
    <div>
      <div>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel htmlFor="shipping">Shipping Method</InputLabel>
          <Select
            native
            value={state.shipping}
            onChange={handleShippingMethodChange}
            label="Shipping Method"
            inputProps={{
              name: 'shipping',
              id: 'shipping',
            }}
          >
            {shippingOptions}
          </Select>
        </FormControl>
        {mergeOptionSelect}
        {storeInfo}
      </div>
      {confirmation}
    </div>
  );
}

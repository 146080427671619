/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

console.log('Hello World from Webpacker')
function isInOrAfterState(currentState, desiredState) {
  return(
    document.readyState === desiredState ||
    document.readyState === 'complete' // 'complete' is also 'interactive'
  )
}

function makeReadyStatePromise(document, desiredState) {
  if (isInOrAfterState(document.readyState, desiredState)) {
    return Promise.resolve(null)
  } else {
    const decider = (resolve, _reject) => {
      document.addEventListener('readystatechange', event => {
        if (isInOrAfterState(event.target.readyState, desiredState)) {
          resolve(null)
        }
      })
      // XXX: would we ever reject?
    }
    return new Promise(decider)
  }
}

function applyPopovers(_event) {
  $('[data-toggle="popover"]').popover();
}

makeReadyStatePromise(document, 'complete')
  .then(applyPopovers)
// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

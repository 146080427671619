import React from 'react';
import { Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStylesBold = makeStyles((theme) => ({
  storeInfo: {
    padding: theme.spacing(3),
    margin: theme.spacing(2),
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[5],
    textAlign: 'center',
  },
  link: {
    color: theme.palette.common.white,
    fontWeight: 'bold',
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'underline',
      color: theme.palette.common.white,
    },
    '&:visited': {
      color: theme.palette.common.white,
    },
  },
  openingHours: {
    marginTop: theme.spacing(2),
    fontWeight: 'bold',
    textDecoration: 'underline',
  },
  typographySpacing: {
    marginBottom: theme.spacing(2),
  },
}));

const StoreInformation = () => {
  const classesBold = useStylesBold();

  return (
    <Paper className={classesBold.storeInfo}>
      <Typography variant="body1" className={classesBold.typographySpacing}>
        Orders placed before noon on business days will typically be ready for pickup the same day.
      </Typography>
      <Typography variant="body1" className={classesBold.typographySpacing}>
        Orders can be picked up from{' '}
        <a
          href="https://www.mtgmate.com.au/pages/storefront"
          target="_blank"
          rel="noopener noreferrer"
          className={classesBold.link}
        >
          our store in Brisbane
        </a>{' '}
        once you have received an email confirming your order is ready for collection.
      </Typography>
      <Typography variant="body1" className={classesBold.typographySpacing}>
        Our store is located at{' '}
        <a
          href="https://maps.app.goo.gl/a9sFeECdUWd73Mz68"
          target="_blank"
          rel="noopener noreferrer"
          className={classesBold.link}
        >
          Unit 1, 471 Tufnell Road, Banyo, QLD, 4014
        </a>
      </Typography>
      <Typography variant="body1" className={classesBold.openingHours}>
        Opening Hours
      </Typography>
      <Typography variant="body1">
        Monday: Closed<br />
        Tuesday - Friday: 2:00 PM - 5:00 PM<br />
        Saturday: Closed<br />
        Sunday: Closed<br />
        Public Holidays: Closed<br />
      </Typography>
    </Paper>
  );
};

export default StoreInformation;

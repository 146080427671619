// MoreInfoButton.js
import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import InfoSection from './InfoSection.js';

const MoreInfoButton = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="contained" color="secondary" onClick={handleOpen}>
        Instructions
      </Button>
      <Modal open={open} onClose={handleClose}>
        <>
          <InfoSection />
        </>
      </Modal>
    </div>
  );
};

export default MoreInfoButton;

import React from 'react';

import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import axios from 'axios'

import { makeStyles } from '@material-ui/core/styles';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';

import CardImagePopover from './CardImagePopover.js'

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function AlertDialog({
  initialPicklistCards,
  markAsPickedUrl,
  removeFromPicklistUrl,
  allow_changes
}) {
  const classes = useStyles();
  const [state, setState] = React.useState(
    {
      picklistCards: JSON.parse(initialPicklistCards),
      pickerQuantity: 0,
      open: false,
      activeCardKey: null,
      action: "Write Off",
      finaliseOpen: false,
      pickedUrl: markAsPickedUrl,
      allowChanges: allow_changes,
      showRemoveDialog: false,
      errorMessage: null,
      writeOffAll: true,
    }
  )

  const {
    picklistCards, pickerQuantity,
    open, activeCardKey, action,
    finaliseOpen, pickedUrl, allowChanges, showRemoveDialog, errorMessage, writeOffAll
  } = state

  const totalCards = () => {
    return Object.values(picklistCards)
      .filter(card => card.display)
      .map(card => card.quantity - card.quantityToWriteOff)
      .reduce((total, difference) => total + difference, 0);
  };

  const totalCardsWrittenOff = () => {
    return Object.values(picklistCards)
      .filter(card => card.display)
      .reduce((total, card) => total + card.quantityToWriteOff, 0);
  };

  const colorClass = (value) => {
    return value > 0 ? "secondary" : "primary";
  };

  const removeFromPicklist = () => {
    axios.post(removeFromPicklistUrl, {
      card_sku_id: activeCard.card_sku_id,
      authenticity_token: Rails.csrfToken()
      }
    ).then(
      response => {
        activeCard.display = false
        handleClose()
      }
    ).catch(error => {
      setState(prevState =>({
        ...prevState,
        errorMessage: `Error removing ${activeCard.picklistName} from picklist: ${error.message} - ${JSON.stringify(error.response.data)}`,
      }))
      handleClose();
    })
  };

  const handleClickOpen = (activeCardKey) => {
    setState({
      ...state,
      open: true,
      activeCardKey: activeCardKey,
    })
  };

  const openRemoveDialog = (activeCardKey) => {
    setState({
      ...state,
      showRemoveDialog: true,
      activeCardKey: activeCardKey,
    })
  };

  const openFinaliseDialog = () => {
    setState({
      ...state,
      open: false,
      finaliseOpen: true,
    })
  };

  const handleClose = () => {
    setState(prevState => ({...prevState, pickerQuantity: 0, open: false, finaliseOpen: false, showRemoveDialog: false}))
  };

  const submitForm = () => {
    activeCard.quantityToWriteOff = parseInt(pickerQuantity)
    setState({
      ...state,
      pickerQuantity: 0,
      open: false,
    })
  };

  const setAllAsWrittenOff = () => {
    const updatedPicklistCards = { ...picklistCards };

    for (const cardKey in updatedPicklistCards) {
      updatedPicklistCards[cardKey].quantityToWriteOff = updatedPicklistCards[cardKey].quantity;
    }

    setState((prevState) => ({
      ...prevState,
      picklistCards: updatedPicklistCards,
      writeOffAll: false,
    }));
  };

  const undoWriteOffAll = () => {
    const updatedPicklistCards = { ...picklistCards };

    for (const cardKey in updatedPicklistCards) {
      updatedPicklistCards[cardKey].quantityToWriteOff = 0;
    }

    setState((prevState) => ({
      ...prevState,
      picklistCards: updatedPicklistCards,
      writeOffAll: true,
    }));
  };

  console.log(picklistCards)
  const cardsToWriteOff = () => {
    const extractedObject = {};
    for (const [key, card] of Object.entries(picklistCards)) {
      if (card.quantityToWriteOff > 0) {
        extractedObject[card.card_sku_id] = card.quantityToWriteOff;
      }
    }

    return extractedObject;
  };

  const markAsPicked = () => {
    axios.post(pickedUrl, {
      changes: cardsToWriteOff(),
      authenticity_token: Rails.csrfToken()
      }
    ).then(
      response => window.location.reload()
    ).catch(error => {
      setState(prevState =>({
        ...prevState,
        errorMessage: `Error finalising this picklist: ${error.message} - ${JSON.stringify(error.response.data)}`,
      }))
      handleClose();
    })
  };

  const handleQuantityChange = (event) => {
    setState({...state, pickerQuantity: event.target.value})
  };

  const activeCard = activeCardKey !== null ? picklistCards[activeCardKey] : {};

  const quantityOptions = (maxQuantity) => {
    const options = [];

    for (let quantityValue = maxQuantity; quantityValue >= 0; quantityValue--) {
      options.push(
        <option key={quantityValue} value={quantityValue}>
          {quantityValue}
        </option>
      );
    }

    return options;
  };

  const ActionButton = ({ label, classNames, onClick, color, style }) => (
    <Button color={color} className={classNames} size="small" variant="outlined" onClick={onClick} style={{marginLeft: '5px'}}>
      {label}
    </Button>
  );

  const TableHeader = () => (
    <TableHead>
      <TableRow>
        <TableCell style={{ width: 70, maxWidth: 70 }} align="left">Set</TableCell>
        <TableCell style={{ width: 70, maxWidth: 70 }} align="left">Code</TableCell>
    <TableCell align="left"><strong>Total: {`${totalCards()}`} - Cards Written Off: {`${totalCardsWrittenOff()}`}</strong></TableCell>
        <TableCell align="right">
          {allowChanges && (
            <>
              <ActionButton
                classNames={"finalise-button"}
                label={totalCardsWrittenOff() === 0 ? 'Mark as Picked' : 'Finalise Write Offs and Mark as Picked'}
                onClick={openFinaliseDialog}
                color={colorClass(totalCardsWrittenOff())}
              />
              <ActionButton
                className="set-quantity-to-write-off"
                onClick={writeOffAll ? setAllAsWrittenOff : undoWriteOffAll }
                label={writeOffAll ? "Mark all as written off" : "Undo  writing  off  all"}
                style={{marginLeft: '1px'}}
              />
            </>
          )}
        </TableCell>
      </TableRow>
      { errorMessage && (
      <TableRow>
        <TableCell style={{color: 'red' }} colSpan="4" align="center"><strong>{errorMessage}</strong></TableCell>
      </TableRow>
      )}
    </TableHead>
  );

  const TableRows = () => (
    Object.keys(picklistCards)
      .filter(cardKey => picklistCards[cardKey].display === true)
      .map((cardKey, index) => {
      const card = picklistCards[cardKey];
      return (
        <TableRow hover key={index} className="card-printing">
          <TableCell style={{width: 70, maxWidth: 70 }} align="left">
            <i className={`ss ss-2x ss-${card.setCode} ss-${card.rarity}`} />
          </TableCell>
          <TableCell style={{width: 70, maxWidth: 70 }} align="left">
            <span style={{fontFamily: "monospace"}} className="set-code">{card.setCode.toUpperCase()}</span>
          </TableCell>
          <TableCell align="left">
            <ActionButton
              classNames={"quantity"}
              label={card.quantityToWriteOff === 0 ? `${card.quantity}` : `${card.quantity - card.quantityToWriteOff} / ${card.quantity}`}
              onClick={allowChanges ? () => handleClickOpen(cardKey) : null}
              color={colorClass(card.quantityToWriteOff)}
            />
            &nbsp;&nbsp;
            <span style={{fontFamily: "monospace"}}>
              <CardImagePopover name={card.picklistName} link={card.cardSkuPath} image={card.cardImage} finish={card.finish} display="inline" />
            </span>
          </TableCell>
          <TableCell align="right">
            {allowChanges ? (
              card.quantityToWriteOff > 0 ? (
                <span className="text-danger">
                  {card.quantityToWriteOff} copies being written off
                </span>
              ) : (
                <ActionButton
                  classNames={"remove-from_picklist"}
                  label="Remove From Picklist"
                  onClick={() => openRemoveDialog(cardKey)}
                  color="default"
                />
              )
            ) : null}
          </TableCell>
        </TableRow>
      )
    })
  )

  return (
    <div>
      <TableContainer component={Paper}>
        <Table stickyHeader className={classes.table} size="small" aria-label="simple table">
          <TableHeader />
          <TableBody>
            <TableRows />
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          How many <strong>{activeCard.picklistName}</strong> would you like to {action}?
        </DialogTitle>
        <DialogContent>
          <FormControl variant="outlined">
            <InputLabel htmlFor="quantity">{action}</InputLabel>
            <Select
              native
              style={{minWidth: 300}}
              value={pickerQuantity}
              onChange={handleQuantityChange}
              label="Quantity"
              inputProps={{
                name: 'quantity',
                id: 'quantity',
              }}
            >
              {quantityOptions(activeCard.quantity)}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button variant="contained" type="submit" onClick={submitForm} color="secondary">
            {action} {pickerQuantity} copies
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={finaliseOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Would you like to write off <strong>{totalCardsWrittenOff()} cards</strong> and mark <strong>{totalCards()} cards</strong> as picked?
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button className="finalise" variant="contained" type="submit" onClick={markAsPicked} color="secondary">
            Write off {totalCardsWrittenOff()} cards and mark {totalCards()} cards as picked
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showRemoveDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Would you like to remove <strong>{activeCard.picklistName}</strong> from the picklist?
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button className="removeFromPicklist" variant="contained" type="submit" onClick={removeFromPicklist} color="secondary">
            Remove from Picklist
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import React, { useState } from 'react';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import { createTheme, withStyles, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { green, brown } from '@material-ui/core/colors';

const ReleaseButton = ({ checkedItems, calculateTotalByKey, submitPath, classes }) => {
  const GreenButton = withStyles((theme) => ({
    root: {
      margin: theme.spacing(1),
      color: '#ffffff',
      backgroundColor: green[500],
      '&:hover': {
        backgroundColor: green[700],
      },
    },
  }))(Button);

  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('Release Cards');
  const [dialogContent, setDialogContent] = useState(
    generateDialogContent(checkedItems)
  );

  const handleButtonClick = () => {
    setDialogTitle("Release Cards")
    setDialogContent(
      generateDialogContent(
        checkedItems, 
        "You are about to release cards from the following storage locations:",
        "Would you like to continue?"
      )
    )
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    // Allow closing the dialog only if not loading
    if (!isLoading) {
      setDialogOpen(false);
    }
  };

  const handleConfirm = () => {
    // Build the request body
    const requestBody = {
      ids: checkedItems.map(item => item.id),
      authenticity_token: Rails.csrfToken(),
    };

    // Display loading spinner
    setDialogTitle("Releasing Cards...")
    setDialogContent(
      generateDialogContent(
        checkedItems, 
        "Currently releasing the following storage locations:",
        "Please wait"
      )
    )
    setLoading(true);

    axios.post(submitPath, requestBody)
      .then(response => {
      })
      .catch(error => {
        console.error('Error during POST request:', error);
      })
      .finally(() => {
        window.location.reload();
        // // Close the dialog and stop loading
        // setLoading(false);
        // setDialogOpen(false);
      });
  };

  function generateDialogContent(items, header, footer) {
    const storageInfo = items.map(item => (
      <li key={item.id}>
        {item.name}: {item.quantities.in_flight_from_glimmerpool || 0} cards
      </li>
    ));

    return (
      <>
        <p>{header}</p>
        <ul>{storageInfo}</ul>
        <p>{footer}</p>
      </>
    );
  }

  const cardsToRelease = calculateTotalByKey(checkedItems, 'in_flight_from_glimmerpool');
  const disabled = cardsToRelease < 1 ;

  return (
    <>
      <GreenButton
        variant="contained"
        color="primary"
        className={classes.releaseButton}
        startIcon={<Icon className="fa fa-envelope-open-text" />}
        onClick={handleButtonClick}
        disabled={disabled}
      >
        Release {cardsToRelease} cards
      </GreenButton>

      <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          {dialogContent}
          {isLoading && <CircularProgress />}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary" disabled={isLoading}>
            Cancel
          </Button>
          <Button onClick={handleConfirm} color="primary" disabled={isLoading}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ReleaseButton;

import React from "react"

import _ from 'lodash'
import classnames from 'classnames'
import CardAutocomplete from './CardAutocomplete.js'

class CardSearch extends React.Component {
  constructor(props) {
    super(props)
    this.goToSearchPage = (searchTerm) => this._searchFunction(searchTerm)
    this.state = {results: []}
    this.url = props.url
  }

  _searchFunction(searchTerm) {
    this.setState({searchTerm})
    if (searchTerm === "") { return }
    window.location.href = this.url + `?q=${searchTerm}`
  }

  render () {
    return (
      <>
        <CardAutocomplete
          onSelected={searchTerm => (this.goToSearchPage(searchTerm)) }
          placeholder={this.props.placeholder}
          autocompleteUrl={this.props.autocompleteUrl}
        />
      </>
    )
  }
}

export default CardSearch

import React from "react"
import PropTypes from "prop-types"

import {createChannel, subscribeMessage} from "./channels.js"

class CartQuantityPill extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.state.message = this.props.initialValue
  }

  componentDidMount() {
    this.channel = createChannel(this.props.channelId)
    subscribeMessage(this.channel, (e) => this.receiveMessage(e))
  }

  receiveMessage({message}) {
    this.setState({message})
  }

  render () {
    return (
      <>
        <span className="badge badge-pill badge-danger"
          style={{
            float: "right",
            marginBottom: "-10px",
            marginRight: "-10px",
          }}
        >
          {this.state.message ? this.state.message : "" }
        </span>
      </>
    );
  }
}

CartQuantityPill.propTypes = {
  channelId: PropTypes.string
};

export default CartQuantityPill

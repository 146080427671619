import React from 'react';
import ErrorSubmissionReport from './ErrorSubmissionReport'; 
import InfoSubmissionReport from './InfoSubmissionReport';

const SubmissionReport = ({ submissionResult }) => (
  <>
    {submissionResult && (
      submissionResult.errors ? (
        <ErrorSubmissionReport submissionResult={submissionResult} />
      ) : (
        <InfoSubmissionReport submissionResult={submissionResult} />
      )
    )}
  </>
);

export default SubmissionReport;

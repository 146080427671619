import React from 'react';
import Popover from '@material-ui/core/Popover';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
}));

const FoilPill = ({ finish }) => {
  let badge = null
  if (finish == "Foil") {
    badge =
      <>
        {" · "}
        <span style={{
          backgroundImage: "linear-gradient(135deg, rgba(222,197,37,0) 0%, rgba(222,197,37,0) 1%, rgba(255,8,8,0.06) 19%, rgba(239,111,23,0.1) 32%, rgba(222,213,37,0.23) 45%, rgba(36,222,101,0.39) 62%, rgba(47,221,109,0.4) 63%, rgba(216,208,239,0.39) 79%, rgba(131,123,173,0.38) 88%, rgba(136,129,178,0.38) 89%, rgba(193,191,234,0) 100%)",
        }} className="badge badge-label">
          Foil
        </span>
      </>
  } else if (finish == "Etched") {
    badge =
      <>
        {" · "}
        <span style={{
          background: "linear-gradient(135deg, #FCF6BA, #FBF5B7, #d49d3b)"
        }} className="badge badge-label">
          Etched
        </span>
      </>
  } else {
    badge = <> </>
  }
  return badge
}

const PreorderPill = ({ preorder }) => {
  let badge = null
  if (preorder) {
    badge =
      <>
        {" · "}
        <span className="badge badge-info">
          Preorder
        </span>
      </>
  } else {
    badge = <> </>
  }
  return badge
}

export default function MouseOverPopover({ name, link, image, finish, preorder = false, display = "block" }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div style={{display: display}}>
      <a
        href={link}
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <span className="card-name">
          {name}
        </span>
      </a>
      <span className="finish">
        <FoilPill {...{ finish }} />
      </span>
      <span>
        <PreorderPill {...{ preorder }} />
      </span>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <div className={finish == "Foil" ? 'pickgradient' : 'non-foil'}>
          <img className="magic-card-rounded-corners popover-image" src={image} alt={name} />
        </div>
      </Popover>
    </div>
  );
}

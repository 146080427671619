import React, { useState, useEffect } from 'react';
import AddToCartPopover from './AddToCartPopover'; // Make sure to adjust the import path
import { createChannel, sendMessage } from "./channels.js"

export default function AddToCartWrapper({ channelId, cart_data, max, uuid }) {
  const channel = createChannel(channelId)
  const [cart, setCart] = useState(JSON.parse(cart_data) || { quantity: 0, pending: false, error: null });

  const mergeCartState = (newState) => {
    setCart({ ...cart, ...newState })
  }
  useEffect(() => {
    const totalQuantity = Object.values(cart)
      .map(item => item.quantity || 0)
      .reduce((acc, val) => acc + val, 0);
    sendMessage(channel, totalQuantity);
  }, [cart, channel]);

  const addToCartAjax = ({ uuid, quantityToAdd }) => {
    let attributes = new FormData()
    attributes.append('card_sku[id]', uuid)
    attributes.append('card_sku[quantity]', quantityToAdd)
    attributes.append('authenticity_token', Rails.csrfToken())
    mergeCartState(
      {[uuid]: { pending: true, error: false, quantity: quantityToAdd }}
    )

    fetch(`/cart/add_item_json`,
      {
        method: "post",
        body: attributes
      }
    )
      .then(response => {
        if (response.ok) { return response }
        throw new Error(response.statusText)
      })
      .then(response => response.json())
      .then(response => {
        mergeCartState({[uuid]: { pending: false, quantity: quantityToAdd }})
      })
      .catch(error => {
        mergeCartState({[uuid]: { pending: false, error: error, quantity: undefined }})
      })
  }

  return (
    <AddToCartPopover
      min={1}
      max={max}
      uuid={uuid}
      addToCart={addToCartAjax}
      cart={cart[uuid]}
    />
  );
}

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

export default function RestockNotificationButton({uuid}) {
  const [disabled, setDisabled] = React.useState(false)
  const [color, setColor] = React.useState("btn-info")
  const [icon, setIcon] = React.useState("fa-bullhorn")

  const handleClick = (event, uuid) => {
    restockNotificationAjax({uuid})
  };

  const restockNotificationAjax = ({uuid}) => {
    let attributes = new FormData()
    attributes.append('card_sku[id]', uuid)
    attributes.append('authenticity_token', Rails.csrfToken())

    fetch(`/restock_notifications/add_item_json`,
      {
        method: "post",
        body: attributes
      }
    )
    .then(response => {
      if (response.ok) { return response }
      throw new Error(response.statusText)
    })
    .then(response => response.json())
    .then(response => {
      setColor("btn-success")
      setIcon("fa-thumbs-up")
      setDisabled(true)
    })
  }

  return (
    <div>
      <button className={`btn ${color}`} type="submit" value="Notify me when restocked"
        disabled={disabled} variant="contained" color="primary"
        onClick={ (e) => {handleClick(e, uuid)}}>
        <i className={`fa ${icon}`}></i>
      </button>
    </div>
  );
}

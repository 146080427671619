import {
  FormGroup,
  FormLabel,
  TextField,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import React from "react"
import MUIDataTable from "mui-datatables";
import CardImagePopover from './../CardImagePopover.js'
import AddToCartPopover from './../AddToCartPopover.js'
import DisabledAddToCartButton from './DisabledAddToCartButton.js'
import Cookies from 'js-cookie'
import { createChannel, sendMessage } from "./../channels.js"
import { createMuiTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles';

function getCookie({ value, defaultValue }) {
  let param = Cookies.get(value)
  let cookieValue;
  if (param === undefined) {
    cookieValue = defaultValue
  } else {
    cookieValue = JSON.parse(param)

  }
  return cookieValue
}

class MagicSetTable extends React.Component {
  constructor(props) {
    super(props)
    this.displaySetName = this.props.displaySetName
    this.currentUserId = this.props.currentUserId
    this.title = this.props.title
    this.cards = this.props.cards
    this.state = {
      results: [],
      cart: JSON.parse(this.props.cart_data)
    }
    this.url = props.url
    this.card_blob = this.props.uuid
    let mediaMatch = window.matchMedia("(max-width: 700px)")
    this.desktop = !(mediaMatch.matches)
  }

  getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MuiTableRow: {
          root: {
            '& .MuiTableCell-root:first-child': {
              paddingLeft: '16px',
            },
            '& .MuiTableCell-root:last-child': {
              paddingRight: '16px',
            },
            '& .MuiTableCell-root': {
              paddingLeft: '4px',
              paddingLeft: '0px',
              paddingTop: '8px',
              paddingBottom: '8px',
            }
          }
        },
        MuiPopover: {
          root: {
            '& .MUIDataTableToolbar-filterPaper-24 ': {
              maxWidth: '80%',
            },
          }
        },
      },
    });

  updateCartTotal() {
    let message = Object
      .values(this.state.cart)
      .map(({ quantity }) => quantity)
      .reduce((acc, val) => { return acc + val }, 0)
    sendMessage(this.channel, message)
  }

  componentDidMount() {
    this.channel = createChannel(this.props.channelId)
    this.updateCartTotal()
  }

  deepMergeState = (newState) => {
    let x = _.merge({}, this.state, newState)
    this.setState(x)
  }

  addToCartAjax = ({ uuid, quantityToAdd }) => {
    let attributes = new FormData()
    attributes.append('card_sku[id]', uuid)
    attributes.append('card_sku[quantity]', quantityToAdd)
    attributes.append('authenticity_token', Rails.csrfToken())
    this.deepMergeState({
      cart: {
        [uuid]: { pending: true, error: false, quantity: quantityToAdd }
      }
    })

    fetch(`/buylist/cart/add_item_json`,
      {
        method: "post",
        body: attributes
      }
    )
    .then(response => {
      return response.json().then(data => {
        if (!response.ok) {
          const errorMessage = data.error || response.statusText;
          throw new Error(errorMessage);
        }
        return data;
      })
    })
    .then(data => {
      this.deepMergeState({
        cart: {
          [uuid]: { pending: false, quantity: quantityToAdd, error: false }
        }
      })
      this.updateCartTotal();
    })
    .catch(error => {
      this.deepMergeState({
        cart: {
          [uuid]: { pending: false, error: error.message, quantity: undefined }
        }
      })
    })
  }

  render() {
    let that = this
    let filterOptions = ['Mythic', 'Rare', 'Uncommon', 'Common', 'Special']
    let filterOptionsFunction
    filterOptionsFunction = function (colorIdentity, filterVal) {
      let rarity = that.card_blob[colorIdentity].rarity

      const show =
        (filterVal.indexOf('Special') >= 0 && ['special', 'timeshifted'].includes(rarity)) ||
        (filterVal.indexOf('Mythic') >= 0 && rarity.includes('mythic')) ||
        (filterVal.indexOf('Rare') >= 0 && rarity.includes('rare')) ||
        (filterVal.indexOf('Uncommon') >= 0 && rarity.includes('uncommon')) ||
        (filterVal.indexOf('Common') >= 0 && rarity.includes('common')) && !rarity.includes('uncommon')
      return !show;
    };

    let columns = [
      {
        name: "variant",
        label: "Variant",
        options: {
          display: false,
          sort: false,
          filter: true,
          viewColumns: false,
          filterList: getCookie({ value: 'bl-variant', defaultValue: [] }),
          filterOptions: {
            names: ["Regular Frame", "Extended Art", "Borderless", "Showcase", "Full Art"],
            logic: function (uuid, filterVal) {
              let name = that.card_blob[uuid].name
              const show =
                (filterVal.indexOf('Regular Frame') >= 0 && !name.includes('(')) ||
                (filterVal.indexOf('Extended Art') >= 0 && name.includes('Extended Art')) ||
                (filterVal.indexOf('Borderless') >= 0 && name.includes('Borderless')) ||
                (filterVal.indexOf('Showcase') >= 0 && name.includes('Showcase')) ||
                (filterVal.indexOf('Full Art') >= 0 && name.includes('Full Art'))
              return !show;
            }
          },
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <>
                {value}
              </>
            )
          },
        }
      },

      {
        name: "finish",
        label: "Finish",
        options: {
          display: false,
          sort: false,
          filter: true,
          viewColumns: false,
          filterList: getCookie({ value: 'bl-finish', defaultValue: [] }),
          filterOptions: {
            names: ["Nonfoil", "Foil", "Etched"],
            logic: function (finish, filterVal) {
              const show =
                (filterVal.indexOf('Nonfoil') >= 0 && finish == 'Nonfoil') ||
                (filterVal.indexOf('Foil') >= 0 && finish == 'Foil') ||
                (filterVal.indexOf('Etched') >= 0 && finish == 'Etched')
              return !show;
            }
          },
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <>
                {value}
              </>
            )
          },
        }
      },

      {
        name: "rarity",
        label: "★",
        options: {
          sortCompare: (order) =>
            (a, b) => {
              let value = (that.card_blob[a.data].rarity > that.card_blob[b.data].rarity) ? 1 : -1
              let direction = (order === 'asc' ? 1 : -1)
              return value * direction
            },
          display: getCookie({ value: 'bl-rarityView', defaultValue: this.desktop }),
          sort: true,
          filter: true,
          filterList: getCookie({ value: 'bl-rarity', defaultValue: [] }),
          filterOptions: {
            names: filterOptions,
            logic: filterOptionsFunction,
          },
          customBodyRender: (value, tableMeta, updateValue) => {
            let setCode = this.card_blob[value].set_code
            if (setCode == 'tsb') {
              setCode = 'tsp'
            }
            let rarity = this.card_blob[value].rarity

            let css = `ss ss-${rarity} ss-${setCode}`
            return (
              <>
                <i style={{ paddingLeft: '4px' }} className={css}></i>
              </>
            )
          },
        }
      },

      {
        name: "name",
        label: "Name",
        options: {
          sortCompare: (order) =>
            (a, b) => {
              let value = (
                that.card_blob[a.data].name + that.card_blob[a.data].set
                > that.card_blob[b.data].name + that.card_blob[b.data].set
              ) ? 1 : -1
              let direction = (order === 'asc' ? 1 : -1)
              return value * direction
            },

          sort: true,
          viewColumns: false,
          filter: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            // tableMeta seems to be the correct way to get data between cells
            let card = that.card_blob[value]
            let set_name
            if (this.displaySetName) {
              set_name = <>
                <span
                  className="set-name font-italic text-muted">
                  {that.card_blob[value].set_name}
                </span>
              </>
            }

            return (
              <>
                <CardImagePopover name={card.name} link={card.link_path} image={card.image} finish={card.finish} />
                {set_name}
              </>

            )

          }
        }
      },
      {
        name: "colour",
        label: "Colour",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            let symbols = value.map(symbol => {
              let cssClass = `ms ms-${symbol.toLowerCase()} ms-cost ms-shadow`
              return <i key={symbol} className={cssClass}></i>
            }
            )
            return (
              <>{symbols}</>
            )
          },
          sortCompare: (order) =>
            (a, b) => {
              let value = (a.data > b.data) ? 1 : -1
              let direction = (order === 'asc' ? 1 : -1)
              return value * direction
            },
          sort: true,
          filter: true,
          display: getCookie({ value: 'bl-colourView', defaultValue: this.desktop }),
          hint: 'If your commander is these colors, you can play this card',
          filterType: 'multiselect',
          filterList: JSON.parse(Cookies.get('colour') || "[]"),
          filterOptions: {
            names: ['White', 'Blue', 'Black', 'Red', 'Green', 'Colorless'],
            logic(colorIdentity, filterVal) {
              const show =
                (filterVal.indexOf('White') >= 0 && colorIdentity.includes('W')) ||
                (filterVal.indexOf('Blue') >= 0 && colorIdentity.includes('U')) ||
                (filterVal.indexOf('Black') >= 0 && colorIdentity.includes('B')) ||
                (filterVal.indexOf('Red') >= 0 && colorIdentity.includes('R')) ||
                (filterVal.indexOf('Green') >= 0 && colorIdentity.includes('G')) ||
                (filterVal.indexOf('Colorless') >= 0 && colorIdentity.includes('C'))
              return !show;
            },
          },
        }
      },
      {
        name: "type",
        label: "Type",
        options: {
          display: getCookie({ value: 'bl-typeView', defaultValue: false }),
          sort: true,
          filter: true,
          filterType: 'multiselect',
          filterList: JSON.parse(Cookies.get('type') || "[]"),
          filterOptions: {
            names: ['Planeswalker', 'Enchantment', 'Sorcery', 'Land', 'Artifact', 'Creature', 'Instant'],
            logic(type, filterVal) {
              const show =
                (filterVal.indexOf('Planeswalker') >= 0 && type.includes('Planeswalker')) ||
                (filterVal.indexOf('Enchantment') >= 0 && type.includes('Enchantment')) ||
                (filterVal.indexOf('Sorcery') >= 0 && type.includes('Sorcery')) ||
                (filterVal.indexOf('Land') >= 0 && type.includes('Land')) ||
                (filterVal.indexOf('Artifact') >= 0 && type.includes('Artifact')) ||
                (filterVal.indexOf('Creature') >= 0 && type.includes('Creature')) ||
                (filterVal.indexOf('Instant') >= 0 && type.includes('Instant'))
              return !show;
            },
          },
        }
      },
      {
        name: "quantity",
        label: "Qty",
        options: {
          setCellHeaderProps: () => ({ style: { textAlign: 'right' } }),
          setCellProps: () => ({ style: { textAlign: 'right' } }),
          sort: true,
          filter: true,
          viewColumns: false,
          filterType: 'dropdown',
          filterList: getCookie({ value: 'bl-quantity', defaultValue: [] }),

          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div className="text-monospace quantity">
                {value} @
              </div>
            )
          },

          filterOptions: {
            names: ['Wanted', 'Not Buying'],
            logic(uuid, filterVal) {
              const show =
                (filterVal.indexOf('Wanted') >= 0 && uuid > 0) ||
                (filterVal.indexOf('Not Buying') >= 0 && uuid < 1);
              return !show;
            },
          },
        }
      },
      {
        name: "buy_price",
        label: "Cash",
        options: {
          sortCompare: (order) =>
            (a, b) => {
              return (that.card_blob[a.data].buy_price - that.card_blob[b.data].buy_price) * (order === 'asc' ? 1 : -1)
            },

          setCellHeaderProps: () => { return { align: "right" } },
          setCellProps: () => { return { align: "right" } },
          display: true,
          sort: true,
          filter: true,
          filterList: getCookie({ value: 'bl-buy_price', defaultValue: [] }),
          viewColumns: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div className="text-monospace">
                ${(this.card_blob[value].buy_price / 100).toFixed(2)}
              </div>
            )
          },
          // Customer filter start

          filterType: 'custom',

          // if the below value is set, these values will be used every time the table is rendered.
          // it's best to let the table internally manage the filterList
          //filterList: [25, 50],

          customFilterListOptions: {
            render: v => {
              if (v[0] && v[1] && this.state.priceFilterChecked) {
                return [`Min Price: ${v[0]}`, `Max Price: ${v[1]}`];
              } else if (v[0] && v[1] && !this.state.priceFilterChecked) {
                return `Min Price: ${v[0]}, Max Price: ${v[1]}`;
              } else if (v[0]) {
                return `Min Price: ${v[0]}`;
              } else if (v[1]) {
                return `Max Price: ${v[1]}`;
              }
              return [];
            },
            update: (filterList, filterPos, index) => {
              if (filterPos === 0) {
                filterList[index].splice(filterPos, 1, '');
              } else if (filterPos === 1) {
                filterList[index].splice(filterPos, 1);
              } else if (filterPos === -1) {
                filterList[index] = [];
              }

              return filterList;
            },
          },

          filterOptions: {
            names: [],
            logic(uuid, filters) {
              let price = that.card_blob[uuid].buy_price / 100
              if (filters[0] && filters[1]) {
                return price < filters[0] || price > filters[1];
              } else if (filters[0]) {
                return price < filters[0];
              } else if (filters[1]) {
                return price > filters[1];
              }
              return false;
            },
            display: (filterList, onChange, index, column) => (
              <div>
                <FormLabel>Price</FormLabel>
                <FormGroup row>
                  <TextField
                    label='Min'
                    value={filterList[index][0] || ''}
                    onChange={event => {
                      filterList[index][0] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%', marginRight: '5%' }}
                  />
                  <TextField
                    label='Max'
                    value={filterList[index][1] || ''}
                    onChange={event => {
                      filterList[index][1] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%' }}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.priceFilterChecked}
                        onChange={event => this.setState({ priceFilterChecked: event.target.checked })}
                      />
                    }
                    label='Separate Values'
                    style={{ marginLeft: '0px' }}
                  />
                </FormGroup>
              </div>
            ),
          },


          // Customer filter end
        }
      },
      {
        name: "credit_price",
        label: "Credit",
        options: {
          sortCompare: (order) =>
            (a, b) => {
              return (that.card_blob[a.data].credit_price - that.card_blob[b.data].credit_price) * (order === 'asc' ? 1 : -1)
            },
          setCellHeaderProps: () => { return { align: "right" } },
          setCellProps: () => { return { align: "right" } },
          display: getCookie({ value: 'bl-credit_priceView', defaultValue: this.desktop }),
          filter: false,
          sort: true,
          viewColumns: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div className="text-monospace">
                ${(this.card_blob[value].credit_price / 100).toFixed(2)}
              </div>
            )
          },
        }
      },
      {
        name: "uuid",
        label: " ",
        options: {
          filter: false,
          sort: false,
          viewColumns: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            let quantity = this.card_blob[value].quantity
            let result
            if (quantity > 0) {
              result = <>
                <AddToCartPopover
                  cart={this.state.cart[value]}
                  addToCart={this.addToCartAjax}
                  min={1}
                  max={quantity}
                  uuid={value}
                  icon={'coins'}
                />
              </>
            } else {
              result = <DisabledAddToCartButton />
            }

            return (
              <>{result}</>
            )
          }

        }
      },
    ]

    let options = {
      onViewColumnsChange: (changedColumn, action) => {
        let visiblity = (action === 'add') ? true : false
        Cookies.set(`bl-${changedColumn}View`, visiblity)
      },
      filterType: 'multiselect',
      //responsive: 'stacked', // Simple Stacked
      responsive: 'standard', // Simple Stacked
      rowsPerPage: getCookie({ value: 'bl-rowsPerPage', defaultValue: 20 }),
      rowsPerPageOptions: [20, 100, 500],
      onChangeRowsPerPage: (number) => {
        Cookies.set('bl-rowsPerPage', number)
      },
      selectableRows: 'none',
      download: false,
      print: false,
      sortFilterList: false,
      onFilterChange: (filterName, selectedFiltersArray, _fitlerType, filterTypeIndex) => {
        Cookies.set(`bl-${filterName}`, selectedFiltersArray[filterTypeIndex])
      },
      setTableProps: () => {
        return {
          padding: 'default',
          size: this.desktop ? 'small' : 'medium'
        }
      },
      customSearch: (searchQuery, currentRow, columns) => {
        let isFound = false;
        let searchableRow = [
          this.card_blob[currentRow[0]].name,
          this.card_blob[currentRow[0]].rarity,
          this.card_blob[currentRow[0]].finish,
          this.card_blob[currentRow[0]].set_name,
          this.card_blob[currentRow[0]].set_code,
          this.card_blob[currentRow[0]].type,
        ]
        searchableRow.forEach(col => {
          if (col.toString().toLowerCase().indexOf(searchQuery.toLowerCase()) >= 0) {
            isFound = true;
          }
        });
        return isFound;
      },
    }

    return (
      <>
        <MuiThemeProvider theme={this.getMuiTheme()}>
          <MUIDataTable
            title={

              <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <div className='mt-2' style={{ textAlign: 'center' }}>

                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item"><a key={2} href="/buylist/magic_sets">Buylist Sets</a></li>
                      <li className="breadcrumb-item active" aria-current="page">{this.title}</li>
                    </ol>
                  </nav>
                </div>
              </div>
            }
            data={this.cards}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      </>
    )
  }
}

export default MagicSetTable

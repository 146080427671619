import React, { useState } from 'react';
import axios from 'axios'; // Assuming you're using axios for AJAX requests
import {
  Button,
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  FormControlLabel,
  Typography,
  Paper,
} from '@material-ui/core';

import SubmissionReport from './buylist_attacher/SubmissionReport.js';
import AttachModal from './buylist_attacher/AttachModal.js';
import MoreInfoButton from './buylist_attacher/MoreInformation.js';
import FileAttachFormElement from './buylist_attacher/FileAttachFormElement.js';

const BuylistAttacherForm = ({ apiEndpoint, currentUserEmail, currentUserName }) => {
  const [loading, setLoading] = useState(false); // State variable for loading modal
  const [csrfToken, setCsrfToken] = useState(Rails.csrfToken()); // Fetch CSRF token

  const [paymentType, setPaymentType] = useState('buy');
  const [bsb, setBsb] = useState('00000');
  const [accountNumber, setAccountNumber] = useState('000000000');
  const [accountName, setAccountName] = useState('MTG MATE Internal Buylist');
  const [email, setEmail] = useState(currentUserEmail || '');
  const [addressName, setAddressName] = useState(`Staff Import: ${currentUserName}` || 'Staff Import');
  const [addressLine1, setAddressLine1] = useState('PO BOX 169');
  const [addressLine2, setAddressLine2] = useState('');
  const [addressCity, setAddressCity] = useState('Banyo');
  const [addressState, setAddressState] = useState('QLD');
  const [addressPostcode, setAddressPostcode] = useState('4014');
  const [csvFile, setCsvFile] = useState(null);
  const [buylistPriceOverride, setBuylistPriceOverride] = useState('');
  const [quantityOverride, setQuantityOverride] = useState(false);
  const [submissionResult, setSubmissionResult] = useState('');

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setLoading(true); // Show loading modal

    try {
      const formData = new FormData();
      formData.append('authenticity_token', csrfToken); // Include CSRF token in form data
      formData.append('payment_type', paymentType);
      formData.append('bsb', bsb);
      formData.append('account_number', accountNumber);
      formData.append('account_name', accountName);
      formData.append('email', email);
      formData.append('address_name', addressName);
      formData.append('address_line_1', addressLine1);
      formData.append('address_line_2', addressLine2);
      formData.append('address_city', addressCity);
      formData.append('address_state', addressState);
      formData.append('address_postcode', addressPostcode);
      formData.append('csv', csvFile);
      formData.append('buylist_price_override', buylistPriceOverride);
      formData.append('quantity_override', quantityOverride ? 'on' : 'off');

      const response = await axios.post(apiEndpoint, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      setSubmissionResult(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error submitting form:', error);
      setLoading(false);
      setSubmissionResult({
        errors: error,
        message: 'Error occurred during submission.'
      })
    }
  };

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <form onSubmit={handleFormSubmit} encType="multipart/form-data">
            <input type="hidden" name="authenticity_token" value={csrfToken} /> {/* Include hidden CSRF token field */}
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel id="payment-type-label">Payment Type</InputLabel>
                  <Select
                    labelId="payment-type-label"
                    id="payment-type"
                    value={paymentType}
                    onChange={(event) => setPaymentType(event.target.value)}
                  >
                    <MenuItem value="buy">Cash</MenuItem>
                    <MenuItem value="credit">Store Credit</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="email"
                  name="email"
                  label="Email"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="address-name"
                  name="address_name"
                  label="Name"
                  value={addressName}
                  onChange={(event) => setAddressName(event.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="address-line-1"
                  name="address_line_1"
                  label="Address Line 1"
                  value={addressLine1}
                  onChange={(event) => setAddressLine1(event.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="address-line-2"
                  name="address_line_2"
                  label="Address Line 2"
                  value={addressLine2}
                  onChange={(event) => setAddressLine2(event.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  id="address-city"
                  name="address_city"
                  label="City"
                  value={addressCity}
                  onChange={(event) => setAddressCity(event.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <InputLabel id="address-state-label">State</InputLabel>
                  <Select
                    labelId="address-state-label"
                    id="address-state"
                    value={addressState}
                    onChange={(event) => setAddressState(event.target.value)}
                    required
                  >
                    <MenuItem value="ACT">Australian Capital Territory</MenuItem>
                    <MenuItem value="NSW">New South Wales</MenuItem>
                    <MenuItem value="NT">Northern Territory</MenuItem>
                    <MenuItem value="QLD">Queensland</MenuItem>
                    <MenuItem value="SA">South Australia</MenuItem>
                    <MenuItem value="TAS">Tasmania</MenuItem>
                    <MenuItem value="VIC">Victoria</MenuItem>
                    <MenuItem value="WA">Western Australia</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  id="address-postcode"
                  name="address_postcode"
                  label="Postcode"
                  value={addressPostcode}
                  onChange={(event) => setAddressPostcode(event.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  fullWidth
                  id="buylist-price-override"
                  name="buylist_price_override"
                  label="Buylist Price Override"
                  value={buylistPriceOverride}
                  onChange={(event) => setBuylistPriceOverride(event.target.value)}
                  placeholder="Price in Cents to set every card to"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={quantityOverride}
                      onChange={(event) => setQuantityOverride(event.target.checked)}
                      name="quantity_override"
                      color="primary"
                    />
                  }
                  label="Accept Excess Quantities?"
                />
              </Grid>
              <Grid item xs={12}>
                <FileAttachFormElement csvFile={csvFile} setCsvFile={setCsvFile} />
              </Grid>
              <Grid item>
                <Button type="submit" variant="contained" color="primary" disabled={!csvFile}>
                  Create and Attach Buylists
                </Button>
              </Grid>
              <Grid item>
                <MoreInfoButton />
              </Grid>
            </Grid>
          </form>
        </Grid>
        <SubmissionReport submissionResult={submissionResult} />
      </Grid>
      <AttachModal open={loading} />
    </div>
  );
};

export default BuylistAttacherForm;

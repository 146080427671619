import React from "react"
import Row from './AdminCardSearchRow.js'

export default function AdminCardSearchTable({json, summary, toggles}) {
  const DollarValue = ({cents}) => {
    return <span>{ (cents / 100 ).toLocaleString("en-US", {style:"currency", currency:"USD"}) }</span>
  }

  const initialFinishToggle = {
    name: "finishToggle",
    color: "secondary",
    label: "All",
    symbol: "filter"
  }

  const initialVisibileToPublicToggle = {
    name: "visibleToPublicToggle",
    color: "secondary",
    label: "All",
    symbol: "filter"
  }

  const initialFilters = {
    visibilityToggle: 'all',
    finishToggle: 'all',
  }

  const [filters, setFilters] = React.useState(initialFilters)
  const [finishToggle, setFinishToggle] = React.useState(initialFinishToggle)
  const [visibleToPublicToggle, setVisibleToPublicToggle] = React.useState(initialVisibileToPublicToggle)

  const resetFilters = () => {
    setFilters(initialFilters)
    setFinishToggle(initialFinishToggle)
    setVisibleToPublicToggle(initialVisibileToPublicToggle)
  }

  const nextFinishState = () => {
    switch (finishToggle.label) {
      case 'All':
        setFinishToggle({
          color: "success",
          label: "Foil",
          symbol: "star"
        })
        setFilters({...filters, finishToggle: "foil"})
        break;
      case 'Foil':
        setFinishToggle({
          color: "warning",
          label: "Etched",
          symbol: "star-half-alt"
        })
        setFilters({...filters, finishToggle: "etched"})
        break;
      case 'Etched':
        setFinishToggle({
          color: "info",
          label: "Nonfoil",
          symbol: "times-circle"
        })
        setFilters({...filters, finishToggle: "nonfoil"})
        break;
      default:
        setFinishToggle({
          color: "secondary",
          label: "All",
          symbol: "filter"
        })
        setFilters({...filters, finishToggle: "all"})
    }
  }

  const nextVisibleToPublicState = () => {
    switch (visibleToPublicToggle.label) {
      case 'All':
        setVisibleToPublicToggle({
          color: "success",
          label: "True",
          symbol: "check"
        })
        setFilters({...filters, visibilityToggle: "true"})
        break;
      case 'True':
        setVisibleToPublicToggle({
          color: "danger",
          label: "False",
          symbol: "times-circle"
        })
        setFilters({...filters, visibilityToggle: "false"})
        break;
      default:
        setVisibleToPublicToggle({
          color: "secondary",
          label: "All",
          symbol: "filter"
        })
        setFilters({...filters, visibilityToggle: "all"})
    }
  }

  const Toggle = ({options, changeState}) => {
    return <button type="button" onClick={ () => changeState() } className={ `btn btn-${options.color} btn-sm`}>
          <i className={`fa fa-${options.symbol}`}></i>
      </button>
  }

  return (
    <div className="row">
      <div className="col">
        <table className="table table-sm table-striped table-bordered">
          <thead className="thead-light table-borderless">
            <tr>
              <th className="table-secondary" colSpan={16}>
              <span style={{textTransform: "capitalize"}}>Filters: <span className="font-weight-light">Visible To Public:</span> {filters.visibilityToggle} · <span className="font-weight-light">Finish:</span> {filters.finishToggle} </span>
              <button onClick={ () => resetFilters() } className="btn btn-outline-danger btn-sm float-right">Reset Filters</button></th>
            </tr>
            <tr>
              <th className="table-secondary rotated-text"><div><span>Visible To Public</span></div></th>
              <th className="table-secondary rotated-text" colSpan={2}><div><span>Card</span></div></th>
              <th className="table-secondary rotated-text"><div><span>Set</span></div></th>
              <th className="table-secondary rotated-text"><div><span>Finish</span></div></th>
              <th className="table-secondary rotated-text"><div><span>Write Off</span></div></th>
              <th className="table-secondary rotated-text"><div><span>Add to Buylist</span></div></th>
              <th className="table-secondary rotated-text"><div><span>Sell Price</span></div></th>
              <th className="table-secondary rotated-text"><div><span>Flags</span></div></th>
              <th className="table-secondary rotated-text"><div><span>In Stock</span></div></th>
              <th className="table-secondary rotated-text"><div><span>Quantity Wanted</span></div></th>
              <th className="table-secondary rotated-text"><div><span>Sold Last 90 days</span></div></th>
              <th className="table-secondary rotated-text"><div><span>Sold Last 365 days</span></div></th>
              <th className="table-secondary rotated-text"><div><span>Last Sold</span></div></th>
              <th className="table-secondary rotated-text"><div><span>Buy Price</span></div></th>
              <th className="table-secondary rotated-text"><div><span>Credit Price</span></div></th>
            </tr>
          </thead>
          <tbody>
                <tr className="thead-light">
                  <th className="text-center">
                    <Toggle options={visibleToPublicToggle} changeState={nextVisibleToPublicState} />
                  </th>
                  <th>
                    <i className="ss ss-xxx ss-common"/>
                  </th>
                  <th className="magic-card-name">
                    Summary
                  </th>
                  <th className="magic-set-name">
                    Totals and Medians
                  </th>
                  <th className="text-center">
                    <Toggle options={finishToggle} changeState={nextFinishState} />
                  </th>
                  <th className="text-center">—</th>
                  <th className="text-center">—</th>
                  <th className="text-right text-monospace"><DollarValue cents={summary.sellPrice} /></th>
                  <th className="text-center">—</th>
                  <th className="text-right text-monospace">{summary.quantity}</th>
                  <th className="text-right text-monospace">{summary.quantityWanted}</th>
                  <th className="text-right text-monospace">{summary.lastNinetyDays}</th>
                  <th className="text-right text-monospace">{summary.last365Days}</th>
                  <th className="text-right">{summary.lastSoldAt}</th>
                  <th className="text-right text-monospace"><DollarValue cents={summary.buyPrice} /></th>
                  <th className="text-right text-monospace"><DollarValue cents={summary.creditPrice} /></th>
                </tr>
          </tbody>
          <tbody>
          {
            json.map((attributes, index, json) => (
              <Row {...attributes} filters={filters} key={index} />
            ))
          }
          </tbody>
        </table>
      </div>
    </div>
  )
}

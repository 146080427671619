import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

const MuiAutocomplete = ({ autocompleteUrl, onChange }) => {

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    limit: 10,
  });

  const [options, setOptions] = React.useState(['']);
  const [value, setValue] = React.useState('');
  const [inputValue, setInputValue] = React.useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(autocompleteUrl);
        const data = await response.json();
        setOptions(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [autocompleteUrl]);


  return (
    <Autocomplete
      value={value}
      filterOptions={filterOptions}
      onChange={(e, newValue) => {
        setValue(newValue)
        onChange(newValue)
      }}
      autoHighlight={true}
      id="combo-box-demo"
      options={options}
      getOptionLabel={(option) => option}
      style={{ width: 300 }}
      renderInput={(params) => <TextField {...params} label="Card Name" variant="outlined" />}
    />
  );
};

export default MuiAutocomplete;
